import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

// App components
import NewFooter from '../../components/NewFooter'
import CustomLink from '../../components/CustomLink'
import MainHelmet from '../../components/MainHelmet'

// App utils
import { rhythm } from '../../utils/typography'
import { themeStyles, presets } from '../../utils/theme'

// App content
import blogger from '../../assets/social/button-blogger.png'
import facebook from '../../assets/social/button-facebook.png'
import google from '../../assets/social/button-google.png'
import logo from '../../assets/logo.png'
import logoWhite from '../../assets/logo-white.png'
import malia from '../../assets/malia.jpg'
import youtube from '../../assets/social/button-youtube.png'

// App utils
import config from '../../utils/config'

const localColors = {
  green: '#007481',
  mediumBlue: '#D4E1E2',
  darkGrey: '#534741',
}

const MAX_WIDTH = '1100px'
const BFEHeader = ({}) => {
  return (
    <div
      css={{
        backgroundImage: "url('/blog-assets/bfe-header.jpg')",
        backgroundSize: 'cover',
        backgroundPosition: 'bottom right',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '30px',
      }}
    >
      <div
        css={{
          width: '100%',
          maxWidth: MAX_WIDTH,
          margin: '0 auto',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <h1 css={{ margin: 0, fontSize: rhythm(1.25) }}>
            BRACES FRIENDLY EATS
          </h1>
          <h2 css={{ margin: 0, fontSize: rhythm(1) }}>BY</h2>
          <img
            src={logo}
            alt={`Collins Orthodontics`}
            css={{ maxWidth: '200px', margin: 0, marginTop: '-10px' }}
          />
        </div>
        <div
          css={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flexWrap: 'wrap',
            justifyContent: 'flex-end',
          }}
        >
          <CustomLink
            to="/schedule-a-free-consult"
            css={{
              textDecoration: 'none',
              textAlign: 'center',
              backgroundColor: localColors.green,
              padding: '10px 30px',
              color: 'white',
              borderRadius: '5px',
              fontSize: rhythm(3 / 4),
            }}
          >
            <span>REQUEST AN</span>{' '}
            <span css={{ display: 'block' }}>APPOINTMENT</span>
          </CustomLink>
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              color: localColors.green,
              marginLeft: rhythm(1),
            }}
          >
            <span>2946 Jeremiah Ln NW</span>
            <span>Rochester, MN 55901</span>
            <span>
              Ph:{' '}
              <CustomLink
                css={{
                  fontSize: rhythm(1),
                  fontWeight: 'bold',
                  color: 'black',
                }}
                href="tel:5072585400"
              >
                507.258.5400
              </CustomLink>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

const BlogItem = ({ node }) => {
  return (
    <CustomLink
      style={{ textDecoration: 'none', boxShadow: 'none', textAlign: 'center' }}
      to={node.fields.slug}
    >
      <img src={node.frontmatter.image} css={{ maxWidth: '300px' }} />
      <h3 css={{ maxWidth: '300px', marginBottom: rhythm(1 / 2) }}>
        {node.frontmatter.title}
      </h3>
      <p css={{ color: localColors.green }}>Full Recipe Here</p>
    </CustomLink>
  )
}

const Malia = () => (
  <div
    css={{
      padding: '20px',
      width: '100%',
      textAlign: 'center',
      ...themeStyles.textPadding,
      width: '100%',
      maxWidth: MAX_WIDTH,
      margin: '0 auto',
    }}
  >
    <h2
      css={{
        fontWeight: 100,
        backgroundColor: localColors.green,
        padding: '20px',
        color: 'white',
        marginBottom: rhythm(2),
      }}
    >
      About "Braces Friendly Eats" by Collins Orthodontics
    </h2>
    <div
      css={{
        [presets.Tablet]: { flexDirection: 'row' },
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <img src={malia} css={{ maxWidth: '200px', objectFit: 'contain' }} />
      <div css={{ paddingLeft: rhythm(1), textAlign: 'left' }}>
        <p>
          When Malia prepared for{' '}
          <CustomLink href="/orthodontics/invisalign">clear braces</CustomLink> treatment at{' '}
          <CustomLink href="/">Collins Orthodontics</CustomLink>, she shared her
          plan with us. Her meal plan! She jumped on the internet, found the
          foods perfect for her first week in braces, and prepped them before
          ever sitting back in our chair at Collins Orthodontics.
        </p>
        <p>
          The results? Collins Orthodontics presents{' '}
          <strong>Braces Friendly Eats featuring Malia Howe</strong> - healthy
          foods you'll love that won't irritate your smile while you start your
          treatment.
        </p>
        <h2 css={{ fontWeight: 100, color: localColors.green }}>MEET MALIA</h2>
        <p>
          Say hello to mom, blogger, and (surprise!) ninja in the kitchen, Malia
          Howe. She never had braces before and recently decided she was ready
          for the perfect smile. And she didn't have to look far for the right
          orthodontist,{' '}
          <CustomLink href="/why-collins/meet-dr-collins">Dr. Grant Collins</CustomLink>, a
          family friend. Together they decided to bring these tasty,
          smile-friendly eats to patients everywehere.
        </p>
        <p>
          Hungry for more? Visit{' '}
          <CustomLink href="http://www.willmakeagain.com/">
            her food blog.
          </CustomLink>
        </p>
      </div>
    </div>
  </div>
)

const Footer = () => (
  <div>
    <div css={{ backgroundColor: localColors.mediumBlue }}>
      <div
        css={{
          width: '100%',
          maxWidth: MAX_WIDTH,
          margin: '0 auto',
          padding: rhythm(1),
        }}
      >
        <h2
          css={{
            fontWeight: '700',
            color: localColors.green,
            textAlign: 'center',
          }}
        >
          Questions about Braces or Invisalign?
        </h2>
        <p css={{ width: '100%', textAlign: 'center', marginBottom: 0 }}>
          Collins Orthodontics Website, Facebook Page, YouTube, Instagram:
          @TheBracesGuy
        </p>
      </div>
    </div>
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: localColors.darkGrey,
      }}
    >
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          maxWidth: MAX_WIDTH,
          margin: '0 auto',
          padding: '30px',
        }}
      >
        <img src={logoWhite} css={{ maxWidth: '250px', margin: 0 }} />
        <div
          css={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flexWrap: 'wrap',
            justifyContent: 'flex-end',
          }}
        >
          <div
            css={{
              display: 'flex',
              maxWidth: '90px',
              flexWrap: 'wrap',
              flexDirection: 'row',
            }}
          >
            <CustomLink href={config.socialLinks.facebook}>
              <img css={{ marginBottom: 0 }} src={facebook} />
            </CustomLink>
            <CustomLink href={config.socialLinks.youtube}>
              <img css={{ marginBottom: 0 }} src={youtube} />
            </CustomLink>
            <CustomLink href={config.socialLinks.google}>
              <img css={{ marginBottom: 0 }} src={google} />
            </CustomLink>
            <CustomLink href={config.socialLinks.blogger}>
              <img css={{ marginBottom: 0 }} src={blogger} />
            </CustomLink>
          </div>
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              color: 'white',
              marginLeft: rhythm(1),
            }}
          >
            <span>2946 Jeremiah Ln NW</span>
            <span>Rochester, MN 55901</span>
            <span>
              Ph:{' '}
              <CustomLink
                css={{
                  fontSize: rhythm(1),
                  fontWeight: 'bold',
                  color: 'white',
                }}
                href="tel:5072585400"
              >
                507.258.5400
              </CustomLink>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
)

class BlogIndex extends React.Component {
  render() {
    const posts = get(this, 'props.data.allMarkdownRemark.edges')

    return (
      <div>
        <MainHelmet
          description={`Find braces friendly recipes here! Sponsored by Collins Orthodontics in Rochester, MN`}
          title={`Braces Friendly Eats by Collins Orthodontics`}
        />
        <BFEHeader />
        <div
          css={{
            ...themeStyles.textPadding,
            width: '100%',
            maxWidth: MAX_WIDTH,
            margin: '0 auto',
          }}
        >
          <div
            css={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'space-around',
              width: '100%',
            }}
          >
            {posts.map(({ node }) => {
              return <BlogItem node={node} key={node.frontmatter.title} />
            })}
          </div>
        </div>
        <Malia />
        <NewFooter />
      </div>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { isBracesFriendlyEats: { eq: true } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            title
            image
          }
        }
      }
    }
  }
`
